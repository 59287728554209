export default {
    ADD_ITEM(state, item) {
        state.SurgeryMarkups.unshift(item)
    },
    SET_SurgeryMarkup(state, SurgeryMarkups) {

        state.SurgeryMarkups = SurgeryMarkups
    },
    UPDATE_SurgeryMarkup(state, SurgeryMarkup) {
        const SurgeryMarkupIndex = state.SurgeryMarkups.findIndex((p) => p.ID == SurgeryMarkup.ID)
        Object.assign(state.SurgeryMarkups[SurgeryMarkupIndex], SurgeryMarkup)
    },
    SET_MarkupPatientCountries(state, MarkupPatientCountries) {
        state.MarkupPatientCountries = MarkupPatientCountries
    },
    SET_MarkupHospitalCountries(state, MarkupHospitalCountries) {
        state.MarkupHospitalCountries = MarkupHospitalCountries
    },
    SET_MarkupSpecialties(state, MarkupSpecialties) {
        state.MarkupSpecialties = MarkupSpecialties
    },
    SET_MarkupSurgeryCategories(state, MarkupSurgeryCategories) {
        state.MarkupSurgeryCategories = MarkupSurgeryCategories
    },
    SET_MarkupServiceTypes(state, MarkupServiceTypes) {
        state.MarkupServiceTypes = MarkupServiceTypes
    },
    SET_MarkupHospitals(state, MarkupHospitals) {
        state.MarkupHospitals = MarkupHospitals
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.SurgeryMarkups.findIndex((p) => p.ID == itemId)
        state.SurgeryMarkups.splice(ItemIndex, 1)
    },
}